html{
  font-size: 14px;
}

h2 {
  font-size: 1.8rem;
  word-break: keep-all;
}

h3 {
  font-size: 1.4rem;
  word-break: keep-all;
}

h4 {
  font-size: 1.25rem;
  word-break: keep-all;
}

#test-offline p {
  font-size: 1.3rem;
  word-break: keep-all;
}

p {
  font-size: 1.15rem;
  word-break: keep-all;
}

body {
  margin: 0;
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.company-table .ant-table-thead > tr > th, 
.company-table .ant-table-tbody > tr > td{
  padding: 5px;
  font-weight: bold;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.login-form {
  width: 100%;
  max-width: 300px;
}
.login-form-forgot {
  float: right;
}
.login-form-button {
  width: 100%;
}

.nav-responsive {
  flex: 1;
  line-height: 64px;
  float: right;
}

.ant-carousel .slick-dots li.slick-active button{
  background: #1890ff;
}

.ant-carousel .slick-dots li button {
    background: #1890ff;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 4px 11px;
}

.dynamic-delete-button {
  cursor: pointer;
  position: relative;
  top: 4px;
  font-size: 24px;
  color: #999;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: #777;
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

.ant-statistic-content {
  color: red;
  font-size: 14px;
}

.ant-table-thead > tr > th {
   text-align: left;
   word-break: keep-all;
   font-size: 1.2rem;
} 

.ant-table-tbody > tr > td {
    text-align: left;
    word-break: keep-all;
    font-size: 1.1rem;
    padding: 10px;
}

.components-table-demo-nested .ant-table-expanded-row:hover > td {
  background: none;
}

#compiled-list img{
  width: 450px;
}

.page {
  position: relative;
  overflow: hidden;
  padding: 0.8in;
  page-break-after: always;
}
.page.landscape {
  width: 11.7in;
  height: 8.2in;
}
.page.portrait {
  width: 8.3in;
  height: 11.6in;
}

button .ant-btn .ant-btn-primary {
  padding: 0 10px;
}

.table-css .ant-table-thead > tr > th {
    background-color: rgba(16, 142, 233, 0.7);
    color: white;
    word-break: keep-all;
    font-size: 1.2rem;
    font-weight: bold;
}

.tablerow-css .ant-table-tbody > tr > td {
    text-align: left;
    word-break: break-all;
    font-size: 1.1rem;
    font-weight: bold;
}

.table-css-company .ant-table-tbody > tr > td {
    text-align: center;
    word-break: keep-all;
    font-size: 1.1rem;
    font-weight: bold;
  }
    

.table-css-company .ant-table-thead > tr > th {
    text-align: center;
    word-break: keep-all;
    font-size: 1.1rem;
    font-weight: bold;
}

.score-pie-detail{
  width: 700px;
}

@media only screen and (max-width: 768px) {
  .score-pie-detail{
    width: 355px;
  }
}

.page-outer {
  background: #fff;
  min-height: 280px;
  text-align: center;
  display: flex;
  justify-content: center;
  height: 99%;
}

.page-inner {
  border: solid 20px #8CD7C0;
  width: 1100px;
  display: flex;
  flex-direction: column;
  padding: 15px;
  height: 100%;
}

#canvas_30 {
  width: 300px;
}

#container-master{
  display: block;
  overflow: auto;
}

@media only screen and (max-width: 768px) {
  .score-pie-detail{
    width: 355px;
  }
  .page-inner {
    border: none;

  }

  #container-master{
    display: flex;
    overflow: scroll;
  }
}

@media only screen and (max-width: 650px) {
  .nav-responsive {
    width: 65px;
    line-height: 64px;
    float: right;
 
  }
  .ant-layout-header {
    padding: 0px 25px;
  }
}

@media only screen and (max-width: 1000px) {
  .nav-responsive {
    line-height: 64px;
    float: right;

  }

  .page-outer {
    background: #fff;
    min-height: 280px;
    text-align: center;
    display: flex;
    justify-content: center;
    height: 99%;
  }

  .page-inner {
    border: solid 20px #8CD7C0;
    display: flex;
    flex-direction: column;
    padding: 15px;
  }

  #container-master{
    display: flex;
    overflow: scroll;
  }
}

.ant-btn {
  font-size: 1.2rem;
  padding: 0px 20px;
  height: 35px;
}

.stamp {
  background-image: url('https://storage.googleapis.com/khra-backend.appspot.com/report-materials/1IGTRbrj2j8UwlEMIqhN6e7DBZadYT499');
  background-repeat: no-repeat;
  height: 100%;
  background-position: 73% 2%;
  display: inline;
}


.logo-stamp {
  background-image: url(https://storage.googleapis.com/khra-backend.appspot.com/report-materials/11jUrcXJrc84c70yoF-mfWWgO5XzS1klP.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 65%;
  display: inline;
}

.analysis-table .ant-table-thead > tr > th {
    word-break: keep-all;
    font-size: 1rem;
}

.offline-list {
  margin-bottom: 0px;
}

.offline-list li {
  padding: 5px;
  font-size: 1.25rem
}

.company-table-list .ant-table-thead > tr > th, 
.company-table-list .ant-table-tbody > tr > td{
  padding: 30px;
  font-weight: bold;
  font-size: 1.4rem;
}


.ncs-table .ant-table-tbody > tr > th {
  font-size: 1.2rem;
  font-weight: bold;
}

.ncs-table .ant-table-tbody > tr > td {
  font-size: 1.2rem;
  font-weight: bold;
  padding: 5px;
}

.ant-descriptions-bordered .ant-descriptions-item-label {
  font-size: 1.2rem;
}

.ant-descriptions-bordered .ant-descriptions-item-label, .ant-descriptions-bordered .ant-descriptions-item-content {
  font-size: 1.2rem;
}